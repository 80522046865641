/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const OUTLOOKS = [
  { value: 2030, rwl: '+2°C', gwl: '+1.5°C' },
  { value: 2050, rwl: '+2.7°C', gwl: '+2°C' },
  { value: 2100, rwl: '+4°C', gwl: '+3°C' },
];

const initialIndex = 1;

const initialState = {
  index: initialIndex,
  ...OUTLOOKS[initialIndex],
};

export const outlookSlice = createSlice({
  name: 'outlook',
  initialState,
  reducers: {
    changeOutlook: (state, action) => {
      const { index } = action.payload;
      state.index = index;
      state.value = OUTLOOKS[index].value;
      state.rwl = OUTLOOKS[index].rwl;
      state.gwl = OUTLOOKS[index].gwl;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeOutlook } = outlookSlice.actions;

export default outlookSlice.reducer;
