import { configureStore } from '@reduxjs/toolkit';
import outlookReducer from './outlookSlice';

export const store = configureStore({
  reducer: {
    outlook: outlookReducer,
  },
});

export default store;
