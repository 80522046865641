import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = responsiveFontSizes(createTheme({
  palette: {
    primary: {
      main: '#db0084',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#003661',
      light: '#036ba1',
      contrastText: '#ffffff',
    },
    background: {
      default: '#ffffff',
    },
    neutral: {
      main: '#7d7d7d',
    },
    text: {
      primary: '#02568d',
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'Quicksand',
      'sans-serif',
    ].join(','),
    h1: {
      fontFamily: 'Roboto',
      fontWeight: 900,
      fontSize: '4.3rem',
      marginBottom: 10,
    },
    h2: {
      fontFamily: 'Roboto',
      fontWeight: 900,
      fontSize: '3.5rem',
      marginBottom: 10,
    },
    h3: {
      fontFamily: 'Roboto',
      fontWeight: 900,
      fontSize: '2rem',
      marginBottom: 15,
    },
    h4: {
      fontFamily: 'Roboto',
      fontWeight: 600,
      fontSize: '1.4rem',
      marginBottom: 10,
    },
    h5: {
      fontFamily: 'Roboto',
      fontWeight: 600,
      fontSize: '1.5rem',
      marginBottom: 15,
    },
    h6: {
      fontFamily: 'Quicksand',
      fontSize: '1.2rem',
    },
    body1: { textAlign: 'justify' },
    body2: { fontWeight: 300 },
    caption: { color: '#7d7d7d' },
    button: {
      fontWeight: 700,
      fontFamily: 'Quicksand',
    },
  },
  breakpoints: {
    values: {
      default: 0,
      xs: 500,
      sm: 640,
      md: 960,
      lg: 1200,
      xl: 1536,
    },
  },
}));

export default theme;
